import React from "react";
import Modal from "../Modal";
import Spinner from "../spinners/Spinner";

export interface LoadingModalProps {
    /**
     * Informative text to show in the modal.
     */
    text: string;

    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ text, visible }) => (
    <Modal visible={visible}>
        <div className="flex flex-col items-center justify-center h-full p-10 px-24 space-y-10">
            <span>{text}</span>
            <Spinner />
        </div>
    </Modal>
);

export default LoadingModal;
