/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from "react";

import axios, { AxiosError } from "axios";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import download from "downloadjs";
import { useDispatch } from "react-redux";
import { dataPending, saveData } from "../redux/reservations";
import axiosApi from "../axiosApi";
import Button from "../components/Button";
import Container from "../components/Container";
import List from "../components/List/List";
import Pagination from "../components/Pagination";
import ScreenTemplate from "../components/ScreenTemplate";
import SearchBox from "../components/SearchBox";
import TabController from "../components/Tab/TabController";
import Tab from "../components/Tab/Tab";
import {
    DayBlockData,
    ModelRef,
    TravelDayData,
    TemplateDetailData,
    TemplateListData,
    MokenImage,
    ContactFullData,
} from "../types";
import useAlert from "../useAlert";
import TextInput from "../components/TextInput";
import { formatDate, getChoice, getItineraryPdfName, getLowestInvalidId } from "../utils";
import DatetimePicker from "../components/DatetimePicker";
import useModelRef from "../useModelRef";
import Selection from "../components/Selection";
import Spinner from "../components/spinners/Spinner";
import Dropdown from "../components/Dropdown/Dropdown";
import DropdownItem from "../components/Dropdown/DropdownItem";
import ItineraryTab from "./ItineraryTab";
import DestinationsTab from "./DestinationsTab";
import PricingSection from "./PricingSection";
import LoadingModal from "../components/modals/LoadingModal";
import TermsTab from "../components/TermsTab";
import Tag from "../components/Tag";
import { openModel, closeModel, hideName } from "../redux/header";
import ImageSelector from "../components/ImageSelector";
import { useAuth } from "../auth";

const PAGE_SIZE = 10;

interface TemplateRow {
    id: number;
    title: string;
    itinerary_days: number;
    destinations: React.ReactNode;
    travel_type: string;
    modified_datetime: string;
    publicSwitch: React.ReactNode;
}

const templateFields: {
    key: keyof TemplateRow;
    title: string;
}[] = [
    {
        key: "title",
        title: "Nombre",
    },
    {
        key: "itinerary_days",
        title: "Días",
    },
    {
        key: "destinations",
        title: "Destinos",
    },
    {
        key: "travel_type",
        title: "Tipo de viaje",
    },
    {
        key: "modified_datetime",
        title: "Última modificación",
    },
    {
        key: "publicSwitch",
        title: "Público",
    },
];

const TemplateScreen: React.FC = () => {
    // List data for currently loaded templates
    const [templates, setTemplates] = useState<Array<TemplateListData> | null>(null);
    // Full data for the currently open template
    const [templateData, setTemplateData] = useState<TemplateDetailData | null>(null);
    const [publicHasChanged, setPublicHasChanged] = useState<boolean | undefined>();
    const [itinerary, setItinerary] = useState<Array<DayBlockData>>([]);
    const [isCreatingNewTemplate, setIsCreatingNewTemplate] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [selectedRows, setSelectedRows] = useState(new Set<number>());
    const [optionsDropdownVisible, setOptionsDropdownVisible] = useState(false);
    const [, setFieldErrors] = useState(new Map<string, string>());
    const [firstCountryImage, setFirstCountryImage] = useState<MokenImage | null>(null);
    const [companyData, setCompanyData] = useState<ContactFullData | null>(null);

    const { addAlert } = useAlert();
    const { getChoiceRef } = useModelRef();
    const dispatch = useDispatch();

    // Modals
    const [isDownloadingPdfModalVisible, setIsDownloadingPdfModalVisible] = useState(false);

    const travelTypes = getChoiceRef("travelType");
    const auth = useAuth();
    let textInputTimer: ReturnType<typeof setTimeout>;

    // TO DO: Find better way to do this
    const linkTemplates = document.querySelector(".templates");
    linkTemplates?.addEventListener("click", () => {
        setTemplateData(null);
    });

    const fetchTemplates = useCallback(
        (searchString) => {
            axiosApi
                .get(
                    `/templates/?limit=${PAGE_SIZE}&offset=${(currentPage - 1) * PAGE_SIZE}${
                        searchString ? `&query=${searchString}` : ""
                    }`
                )
                .then((response) => {
                    setTotalRecords(response.data.count);
                    setTemplates(response.data.results);
                })
                .catch(() => {
                    // FIXME: Using addAlert requires adding it as a dep, which invalidates the callback every time addAlert is called anywhere,
                    // triggering any useEffect that has the fetchTemplates function as a dep
                    // addAlert("Error al recuperar los tarifarios.", "error");
                });
        },
        [currentPage]
    );

    const handleSwitchChange = async (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
        e.stopPropagation();

        axiosApi.get(`/templates/${id}/`).then((response) => {
            const currentTemplate = response.data;
            const currentExternalTemplate = templates?.find((template) => template.id === id);
            if (currentTemplate) currentTemplate.public = !currentTemplate.public;
            if (currentExternalTemplate) currentExternalTemplate.public = !currentExternalTemplate.public;
            axiosApi.put(`/templates/${id}/`, currentTemplate);
        });
    };

    const buildTemplateRow = (template: TemplateListData): TemplateRow => {
        const countries = template.countries.filter((c) => c);
        const destinations = (
            <div className="flex space-x-1 items-center justify-center">
                {countries.length === 0 && <p className=" text-grey-light-2">-</p>}
                {countries.length > 0 && <Tag label={countries[0].name} type="yellow" />}
                {countries.length > 2 && <span>+1</span>}
            </div>
        );

        const publicSwitch = (
            <Switch
                id="public"
                defaultChecked={publicHasChanged ? !template.public : template.public}
                size="small"
                onClick={(e) => handleSwitchChange(e, template.id)}
            />
        );

        return {
            id: template.id,
            title: template.title,
            itinerary_days: template.itinerary_days,
            destinations,
            travel_type: template.travel_type,
            modified_datetime: formatDate(new Date(template.modified_datetime)),
            publicSwitch,
        };
    };

    const openTemplate = (id: number) => {
        if (templateData) return;
        setPublicHasChanged(false);
        // Fetch the full data from the server
        axiosApi
            .get(`/templates/${id}/`)
            .then((response) => {
                if (response.data.itinerary) {
                    // Sort itinerary by position
                    const itinerarySorted: Array<DayBlockData> = response.data.itinerary;
                    itinerarySorted.sort((a, b) => a.position - b.position);
                    setItinerary(itinerarySorted);
                    delete response.data.itinerary;
                }
                setTemplateData(response.data);
                dispatch(openModel({ modelId: response.data.id }));
            })
            .catch(() => {
                addAlert("Error al recuperar los detalles del tarifario.", "error");
            });
    };

    const handleErrorResponse = useCallback(
        (err: Error | AxiosError, isItinerary = false) => {
            if (axios.isAxiosError(err) && err.response) {
                const newFieldsWithError = new Map<string, string>();
                const { data } = err.response;
                const wrongFields = Object.entries(data);
                switch (err.response.status) {
                    case 400:
                        addAlert("Algunos campos requeridos no han sido correctamente rellenados.", "error");
                        for (let i = 0; i < wrongFields.length; i += 1) {
                            const [field, message] = wrongFields[i];
                            if (Array.isArray(message) && typeof message[0] === "string") {
                                newFieldsWithError.set(`${isItinerary ? "itinerary_" : ""}${field}`, message[0]);
                            }
                        }
                        setFieldErrors(newFieldsWithError);
                        break;
                    default:
                        addAlert(`Ha ocurrido un error inesperado. Código de error: ${err.response.status}`, "error");
                        break;
                }
            }
        },
        [setFieldErrors, addAlert]
    );

    const fetchCompany = useCallback(() => {
        if (auth.contact === null) return;
        axiosApi.get("/contacts/company/").then((response) => {
            const data = response.data as ContactFullData;
            setCompanyData(data);
        });
    }, [auth.contact]);

    const createNewTemplate = async () => {
        setIsCreatingNewTemplate(true);
        const newTemplate = {
            id: 0,
            title: "",
            destinations: new Array<string>(),
            itinerary_days: 0,
            amount_travelers: 1,
            interests: new Array<ModelRef>(),
            included: companyData?.config?.included || "",
            not_included: companyData?.config?.not_included || "",
            cancellation_policy: companyData?.config?.cancellation_policy || "",
            comments: "",
            itinerary: new Array<TravelDayData>(),
            public: false,
            currency: "",
            extra_policies: [],
        } as Partial<TemplateDetailData>;

        // Add default extra policies if present
        if (companyData?.config?.extra_policies) {
            for (let i = 0; i < companyData?.config?.extra_policies.length; i += 1) {
                newTemplate.extra_policies?.push({
                    id: getLowestInvalidId(newTemplate.extra_policies),
                    title: companyData?.config?.extra_policies[i].title,
                    text: companyData?.config?.extra_policies[i].text,
                });
            }
        }

        await axiosApi
            .post("/templates/", newTemplate)
            .then((response) => {
                setTemplateData(response.data);
                setItinerary(response.data.itinerary);
                dispatch(openModel({ modelId: response.data.id }));
            })
            .catch((err) => handleErrorResponse(err))
            .finally(() => setIsCreatingNewTemplate(false));
    };

    const handleSave = async () => {
        if (!templateData) return;

        let success = false;

        // Data preparation and formatting before sending it to the server
        // If required selection fields are not properly filled, don't send any data so that
        // we get the "this field is required" message
        if (!templateData.traveler_type) delete templateData.traveler_type;
        if (!templateData.travel_type) delete templateData.travel_type;

        // Update existing reservation
        await axiosApi
            .put(`/templates/${templateData.id}/`, templateData)
            .then((updatedTemplate) => {
                success = true;
                setTemplateData(updatedTemplate.data);
                dispatch(saveData());
            })
            .catch((err) => handleErrorResponse(err));

        if (success) {
            addAlert("Se ha guardado el viaje con éxito.", "success");
        }

        setOptionsDropdownVisible(false);
    };

    const handleDuplicate = (id: number) => {
        axiosApi
            .get(`/templates/${id}/duplicate/`)
            .then(() => {
                addAlert("Se ha duplicado el tarifario con éxito.", "success");
            })
            .catch((err) => handleErrorResponse(err));

        setOptionsDropdownVisible(false);
    };

    const handleDelete = (id: number) => {
        // TODO: Show confirmation dialog
        axiosApi
            .delete(`/templates/${id}/`)
            .then(() => {
                setTemplateData(null);
                setCurrentPage(1);
                setTemplates(null);
                fetchTemplates("");
            })
            .catch((err) => handleErrorResponse(err));

        setOptionsDropdownVisible(false);
    };

    const handleDeleteMultiple = () => {
        if (templateData || selectedRows.size === 0) return;
        // TODO: Allow deleting multiple items with a single query via the API
        // TODO: Show confirmation dialog
        const idArray = Array.from(selectedRows);
        for (let i = 0; i < idArray.length; i += 1) {
            handleDelete(idArray[i]);
        }
        setSelectedRows(new Set());
    };

    // Fetch templates when page changes
    useEffect(() => {
        setPublicHasChanged(false);
        fetchCompany();
        setTemplates(null);
        fetchTemplates("");
        setItinerary([]);
        setIsCreatingNewTemplate(false);
    }, [currentPage, fetchTemplates, fetchCompany]);

    // When template is closed, remove template data from the header
    useEffect(() => {
        if (templateData == null) {
            dispatch(closeModel());
        }
    }, [templateData, dispatch]);

    // When a search query is submitted, reset the list and re-fetch tarifarios using the query
    useEffect(() => {
        setCurrentPage(1);
        setTemplateData(null);
    }, []);

    useEffect(() => {
        if (templateData && templateData?.countries.length > 0) {
            axiosApi
                .get(`/countries/${templateData.countries.at(0)?.id}/image/`)
                .then((r) => {
                    setFirstCountryImage(r.data);
                })
                .catch(() => {
                    addAlert("Ha habido un error al recuperar la imagen disponible del país", "error");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData?.countries]);

    dispatch(saveData());
    dispatch(hideName());

    const loadingScreen = (
        <ScreenTemplate title="Tarifario">
            <Container>
                <div className="flex items-center justify-center h-full w-full">
                    <Spinner />
                </div>
            </Container>
        </ScreenTemplate>
    );
    if (!templates && !templateData) {
        return loadingScreen;
    }

    const listView = (
        <Container
            numSelected={!templateData ? selectedRows.size : undefined}
            actionArea={
                <Button
                    label={`Eliminar ${selectedRows.size} ${selectedRows.size < 2 ? "item" : "items"}`}
                    onClick={handleDeleteMultiple}
                    type="btn_red"
                />
            }
        >
            <div className="flex flex-col h-full px-8">
                <div className="flex w-full">
                    <div className="flex-1 self-start">
                        <SearchBox
                            placeholder="Buscar nombre, destino o fecha..."
                            onChange={(searchString) => fetchTemplates(searchString)}
                            hiddenIcon
                        />
                    </div>
                    <div className="flex flex-1 justify-end">
                        <div className="w-max">
                            <Button
                                type="btn_dark"
                                label="Nuevo"
                                icon="add"
                                onClick={() => createNewTemplate()}
                                title="Añadir nuevo tarifario"
                            />
                        </div>
                    </div>
                </div>
                <p className="block sm:hidden text-[2.2rem] font-black font-sans text-blue-dark mt-16">Tarifario</p>
                <div className="w-full mt-8">
                    {templates && (
                        <div className="flex flex-col h-full">
                            <div className="grow">
                                <List
                                    objects={templates.map((reservation) => buildTemplateRow(reservation))}
                                    fields={templateFields}
                                    listType="templatelist"
                                    multiselection
                                    onRowClick={openTemplate}
                                    selectedRows={selectedRows}
                                    onSelect={setSelectedRows}
                                />
                            </div>
                            {totalRecords > PAGE_SIZE && (
                                <div className="self-end shrink">
                                    <Pagination
                                        currentPage={currentPage}
                                        resultsPerPage={PAGE_SIZE}
                                        totalResults={totalRecords}
                                        onPrevious={() => setCurrentPage(currentPage - 1)}
                                        onNext={() => setCurrentPage(currentPage + 1)}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );

    const summaryTab = templateData && (
        <Tab name="General">
            <div className="h-full w-full overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <Container>
                    <div className="flex flex-col space-y-10 pt-0 sm:pt-8">
                        <h2 className="text-2xl font-bold font-sans">Datos de la solicitud</h2>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                                <DatetimePicker
                                    id="creation_date"
                                    label="Fecha de creación"
                                    defaultValue={templateData.create_datetime}
                                    disabled
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                                <TextInput
                                    id="travel_days"
                                    type="number"
                                    label="Nº Días"
                                    defaultValue={
                                        templateData.itinerary_days ? templateData.itinerary_days.toString() : ""
                                    }
                                    disabled
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
                                <Selection
                                    id="travel_type"
                                    size="small"
                                    label="Tipo de viaje"
                                    choices={travelTypes}
                                    defaultValue={getChoice(templateData.travel_type || "", travelTypes)}
                                    onChange={(choice) => {
                                        setTemplateData({ ...templateData, travel_type: choice.value });
                                        dispatch(dataPending());
                                    }}
                                />
                            </div>
                            <div className="col-span-12 sm:col-span-6 lg:col-span-2 self-center lg:justify-self-center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                id="public"
                                                defaultChecked={templateData.public}
                                                onChange={() => {
                                                    setTemplateData({ ...templateData, public: !templateData.public });
                                                    setPublicHasChanged(true);
                                                    dispatch(dataPending());
                                                }}
                                            />
                                        }
                                        label="Público"
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-span-12">
                                <TextInput
                                    id="title"
                                    type="text"
                                    label="Título"
                                    defaultValue={templateData.title}
                                    onChange={(ev) => {
                                        const value = ev.currentTarget.value;
                                        clearTimeout(textInputTimer);
                                        textInputTimer = setTimeout(() => {
                                            setTemplateData({ ...templateData, title: value });
                                            dispatch(dataPending());
                                        }, 600);

                                        return () => clearTimeout(textInputTimer);
                                    }}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-9 mt-2">
                                <TextInput
                                    id="comments"
                                    type="text"
                                    label="Comentarios"
                                    defaultValue={templateData.comments}
                                    lines={9}
                                    onChange={(ev) => {
                                        const value = ev.currentTarget.value;
                                        clearTimeout(textInputTimer);
                                        textInputTimer = setTimeout(() => {
                                            setTemplateData({ ...templateData, comments: value });
                                            dispatch(dataPending());
                                        }, 600);

                                        return () => clearTimeout(textInputTimer);
                                    }}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3 flex mt-8 lg:mt-4 min-h-[230px]">
                                <ImageSelector
                                    label="Portada"
                                    image={templateData.cover_image}
                                    onAccept={(image: MokenImage) => {
                                        setTemplateData({ ...templateData, cover_image: image });
                                    }}
                                    mokenImage={firstCountryImage || ({ id: 0, image: "" } as MokenImage)}
                                    description={
                                        templateData.cover_image?.is_unsplash
                                            ? `${templateData.cover_image.author} (Unsplash)`
                                            : undefined
                                    }
                                    linkTo={
                                        templateData.cover_image?.is_unsplash
                                            ? templateData.cover_image.author_url
                                            : undefined
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Container>

                <div>
                    <PricingSection
                        currency={templateData.currency}
                        salesOrder={templateData.sales_order}
                        onModifySalesOrders={(sales_order) => setTemplateData({ ...templateData, sales_order })}
                        onModifyCurrency={(currency) => setTemplateData({ ...templateData, currency })}
                    />
                </div>

                {/* Modals */}
                <LoadingModal
                    visible={isDownloadingPdfModalVisible}
                    text="El PDF se está generando. La descarga comenzará en breve..."
                />
                <LoadingModal visible={isCreatingNewTemplate} text="Espera mientras se crea el nuevo viaje..." />
            </div>
        </Tab>
    );

    const destinationsTab = templateData && (
        <Tab name="Destinos">
            <div className="overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <DestinationsTab
                    itinerary={itinerary}
                    setItinerary={setItinerary}
                    reservation={templateData}
                    type="templates"
                />
            </div>
        </Tab>
    );

    const itineraryTab = templateData && (
        <Tab name="Itinerario">
            <div className="overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <ItineraryTab
                    itinerary={itinerary}
                    setItinerary={setItinerary}
                    reservationId={templateData.id}
                    type="templates"
                />
            </div>
        </Tab>
    );

    const termsTab = templateData && (
        <Tab name="Términos">
            <div className="overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <TermsTab
                    reservationData={templateData}
                    setReservationData={(data) => setTemplateData(data as TemplateDetailData)}
                />
            </div>
        </Tab>
    );

    const detailView = templateData && (
        <div className="flex flex-col h-full pl-8 pr-8 pb-10 bg-white">
            <h2 className="block sm:hidden text-[2.2rem] mt-32 font-black font-sans text-blue-dark">{`Tarifario/${templateData.id}`}</h2>
            <div className="flex flex-col-reverse sm:flex-row w-full items-center gap-2 sm:gap-4 justify-end pt-4">
                <div className="w-full sm:w-28 sm:h-16">
                    <Dropdown
                        anchor={
                            <Button
                                type="btn_transparent"
                                label="Opciones"
                                icon="more_vert"
                                title="Opciones de tarifario"
                                extraClass="w-full h-[56px] sm:h-auto"
                            />
                        }
                        visible={optionsDropdownVisible}
                        onClick={() => setOptionsDropdownVisible(!optionsDropdownVisible)}
                        onBlur={(e) => {
                            const currentTarget = e.currentTarget;
                            setTimeout(() => {
                                if (!currentTarget.contains(document.activeElement)) {
                                    setOptionsDropdownVisible(false);
                                }
                            }, 0);
                        }}
                    >
                        <DropdownItem
                            key="print_template"
                            label="Exportar programa"
                            onClick={async () => {
                                await handleSave();
                                setOptionsDropdownVisible(false);
                                setIsDownloadingPdfModalVisible(true);
                                axiosApi
                                    .get(`/templates/${templateData.id}/print/`, {
                                        responseType: "blob",
                                    })
                                    .then((response) => {
                                        download(
                                            response.data,
                                            getItineraryPdfName(templateData),
                                            response.headers["content-type"]
                                        );
                                        setIsDownloadingPdfModalVisible(false);
                                    })
                                    .catch(() => setIsDownloadingPdfModalVisible(false));
                            }}
                        />
                        <DropdownItem
                            key="download_simple_text"
                            label="Descargar texto simple"
                            onClick={async () => {
                                await handleSave();
                                setOptionsDropdownVisible(false);
                                axiosApi
                                    .get(`/templates/${templateData.id}/print_txt/`, {
                                        responseType: "blob",
                                    })
                                    .then((response) => {
                                        download(
                                            response.data,
                                            `${getItineraryPdfName(templateData)}.txt`,
                                            response.headers["content-type"]
                                        );
                                    });
                            }}
                        />
                        <DropdownItem
                            key="duplicate"
                            label="Duplicar viaje"
                            onClick={() => {
                                handleDuplicate(templateData.id);
                            }}
                        />
                        <DropdownItem
                            key="delete"
                            label="Eliminar viaje"
                            labelColor="red"
                            onClick={() => {
                                handleDelete(templateData.id);
                            }}
                        />
                    </Dropdown>
                </div>
                <div className="w-full sm:w-28 h-16">
                    <Button
                        type="btn_dark"
                        label="Guardar"
                        onClick={() => handleSave()}
                        title="Guardar tarifario"
                        extraClass="w-full h-[56px] sm:w-auto sm:h-auto"
                    />
                </div>
            </div>
            <div className="flex w-full h-full sm:h-[calc(100vh-180px)] mt-16 sm:mt-4">
                <TabController lockReason="Guarda los cambios en el nuevo día antes de cambiar de pestaña.">
                    {summaryTab}
                    {destinationsTab}
                    {itineraryTab}
                    {termsTab}
                </TabController>
            </div>
        </div>
    );

    return (
        <ScreenTemplate
            title="Tarifario"
            backButtonVisible={templateData !== null}
            onBack={() => {
                setTemplateData(null);
                fetchTemplates("");
                dispatch(saveData());
            }}
        >
            {templateData ? detailView : listView}
        </ScreenTemplate>
    );
};

export default TemplateScreen;
