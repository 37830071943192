/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";
import { saveData } from "../redux/reservations";
import { hideName } from "../redux/header";
import Map from "../components/Map";
import TextInput from "../components/TextInput";
import ScreenTemplate from "../components/ScreenTemplate";
import axiosApi from "../axiosApi";
import { ContactData, ModelRef, DashboardTravelData, ContactFullData } from "../types";
import EngineSpinner from "../components/spinners/Engine";
import Spinner from "../components/spinners/Spinner";
import useAlert from "../useAlert";
import Avatar from "../components/Avatar";
import { getInitials, getCurrentDate } from "../utils";
import IconButton from "../components/IconButton";
import { useAuth } from "../auth";
import DashboardTravel from "../components/DashboardTravel";

export interface ReservationRequestData {
    id: number;
    reference: string;
    destinations: Array<ModelRef>;
    comments: string;
    travel_start_date: Date;
    travel_days: number;
    amount_travelers: number;
    travel_type: string;
    budget: number;
    name: string;
    surname: string;
    email: string;
    phone: string;
    interests: Array<ModelRef>;
    traveler_type: string;
    contact?: ContactData;
}

const DashboardScreen: React.FC = () => {
    const [companyData, setCompanyData] = useState<ContactFullData | null>(null);
    const [reservationData, setReservationData] = useState<Array<ReservationRequestData> | null>(null);
    const [todayTravels, setTodayTravels] = useState<DashboardTravelData[]>([]);
    const [currentDateToShow, setCurrentDateToShow] = useState<string>(getCurrentDate(null, null, "slashed"));
    const [nextTravels, setNextTravels] = useState<[] | null>([]);
    const [areTravelsLoaded, setAreTravelsloaded] = useState<boolean>(false);
    const [isDayChanged, setIsDayChanged] = useState<boolean>(false);
    const [calendarSelectedDate, setCalendarSelectedDate] = useState<string>("");
    const { addAlert } = useAlert();

    const calendarOptions = {
        wrap: true,
        allowInput: true,
        enableTime: false,
        noCalendar: false,
        time_24hr: true,
        inline: true,
        defaultDate: getCurrentDate(null, null, null),
    };
    const auth = useAuth();
    const dispatch = useDispatch();
    const fetchCompany = useCallback(() => {
        if (auth.contact === null) return;
        axiosApi.get("/contacts/company/").then((response) => {
            const data = response.data as ContactFullData;
            setCompanyData(data);
        });
    }, [auth.contact]);

    /**
     * Fetch next day data recursively
     */
    const fetchNextDayData = useCallback(
        (dateString: string, arrayOfTravels, numberOfDays) => {
            const newdate = new Date(dateString);
            newdate.setDate(newdate.getDate() + numberOfDays);
            const date = `0${newdate.getDate()}`;
            const month = `0${newdate.getMonth() + 1}`;
            const year = newdate.getFullYear();
            const finalDateString = `${year}-${month.slice(-2)}-${date.slice(-2)}`;
            if (numberOfDays > 0) {
                axiosApi
                    .get(`/dashboard/?date=${finalDateString}`)
                    .then((response) => {
                        const data: [] = response.data;
                        data.reverse();
                        data.forEach((item: DashboardTravelData) => {
                            new Date(item.reservation.travel_start_date).getTime() ===
                                new Date(finalDateString).getTime() && arrayOfTravels.push(item);
                        });

                        if (numberOfDays === 1) {
                            arrayOfTravels.reverse();
                            setNextTravels(arrayOfTravels);
                            arrayOfTravels.length && setAreTravelsloaded(true);
                        }
                        fetchNextDayData(dateString, arrayOfTravels, numberOfDays - 1);
                    })
                    .catch(() => {
                        auth.contact !== null && addAlert("Error al recuperar las solicitudes.", "error");
                    });
            } else {
                setAreTravelsloaded(true);
            }
        },
        [addAlert, auth]
    );

    /**
     * Gets data from a fixed numbers o days ahead
     */
    const nextDaysDataHandler = useCallback(
        (dateString: string) => {
            const arrayOfTravels: [] = [];
            const numberOfDaysAhead = 3;
            fetchNextDayData(dateString, arrayOfTravels, numberOfDaysAhead);
        },
        [fetchNextDayData]
    );

    /**
     * Get travel data by day
     */
    const fetchSelectedDayData = useCallback(
        (dateString) => {
            axiosApi
                .get(`/dashboard/?date=${dateString}`)
                .then((response) => {
                    const data = response.data;
                    setTodayTravels(data);
                    setCurrentDateToShow(getCurrentDate(dateString, "dayFirst", "slashed"));
                })
                .catch(() => {
                    auth.contact !== null && addAlert("Error al recuperar las solicitudes.", "error");
                });
        },
        [addAlert, auth]
    );

    const showTooltipAction = (tooltipActive: boolean) => setIsDayChanged(tooltipActive);

    /**
     * Get current pending requests
     */
    const fetchInbox = useCallback(() => {
        axiosApi
            .get("/inbox/?limit=5")
            .then((response) => {
                const data = (response.data.results as Array<ReservationRequestData>).map((req) => {
                    if (!req.contact) {
                        return req;
                    }

                    return {
                        ...req,
                        name: req.contact.name,
                        surname: req.contact.surname || "",
                        phone: req.contact.phone || "",
                        email: req.contact.email,
                    };
                });
                setReservationData(data);
            })
            .catch(() => {
                auth.contact !== null && addAlert("Error al recuperar las solicitudes.", "error");
            });
    }, [addAlert, auth]);

    /**
     * Post render actions
     */
    useEffect(() => {
        fetchCompany();
        const todayDate = getCurrentDate(null, null, null);
        setReservationData(null);
        fetchSelectedDayData(todayDate);
        nextDaysDataHandler(todayDate);
        fetchInbox();
    }, [fetchInbox, fetchSelectedDayData, nextDaysDataHandler, fetchCompany]);

    dispatch(saveData());
    dispatch(hideName());

    const LoadingScreen = (
        <ScreenTemplate title="Dashboard">
            <div className="flex items-center justify-center h-full w-full">
                <Spinner />
            </div>
        </ScreenTemplate>
    );

    if (!reservationData) {
        return LoadingScreen;
    }

    return (
        /* todayTravels &&
        nextTravels && */
        reservationData && (
            <ScreenTemplate title="Dashboard">
                <div>
                    <span className="mt-20 flex sm:hidden flex-1 text-4xl font-black font-sans text-blue-dark px-8 py-2">
                        Dashboard
                    </span>
                    <div className="grid lg:grid-cols-dashboardcalendar--responsive xl:grid-cols-dashboardcalendar gap-4 px-4 sm:px-8 pb-4 md:pb-8 pt-0">
                        <div className=" bg-white rounded-md shadow calendar-container w-74 h-full pl-2 pr-2  pt-4 sm:pl-8 sm:pr-8 pb-6 border border-grey-light-3">
                            <div className="flex justify-between px-2 py-4">
                                <h2 className="text-blue text-xl">Selecciona fecha</h2>
                            </div>
                            <div className="flex items-center justify-center">
                                <Flatpickr
                                    options={calendarOptions}
                                    onChange={(selectedDates, dateStr) => {
                                        setCalendarSelectedDate(dateStr);
                                        nextDaysDataHandler(dateStr);
                                        fetchSelectedDayData(dateStr);
                                        showTooltipAction(false);
                                    }}
                                >
                                    <div className="hidden">
                                        <TextInput
                                            id="calendar"
                                            type="text"
                                            label="mycalendar"
                                            placeholder="mycalendar"
                                            required
                                            disabled={false}
                                            // Component has to either be controlled or uncontrolled, can't be both at the same time.
                                            // For more info see https://reactjs.org/docs/forms.html#controlled-components
                                            defaultValue={undefined}
                                            value="hi"
                                        />
                                    </div>
                                </Flatpickr>
                            </div>
                        </div>
                        <div
                            className={`bg-white rounded-md shadow p-4 border border-grey-light-3 ${
                                companyData?.contact_type === "provider" && "col-span-1"
                            }`}
                        >
                            <div className="flex justify-between p-4">
                                <div>
                                    <h2 className="text-blue text-xl">Próximos viajes</h2>
                                    <p className="text-grey-light-1 text-sm pt-1"> A partir del {currentDateToShow}</p>
                                </div>
                                <p className="text-3xl font-bold">{nextTravels?.length}</p>
                            </div>
                            <div className="relative h-[280px]">
                                <div className="overlay absolute h-[24px] left-0 right-3 bottom-0 bg-gradient-to-t from-white from-1% via-30% to-90% pointer-events-none z-10 " />
                                {areTravelsLoaded ? (
                                    <div className="max-h-[280px] overflow-y-auto">
                                        {nextTravels && nextTravels.length ? (
                                            nextTravels.map((travel: DashboardTravelData, index: number) => (
                                                <DashboardTravel
                                                    calendarSelectedDate={calendarSelectedDate}
                                                    key={travel.reservation.id}
                                                    travel={travel}
                                                    index={index}
                                                    highlightCurrentDay={false}
                                                />
                                            ))
                                        ) : (
                                            <p className="text-grey-light-1 text-2xl flex h-full items-center text-center p-4">
                                                No hay viajes los próximos días
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div className="h-full flex justify-center items-center p-20 opacity-25">
                                        <div className="w-28">
                                            <EngineSpinner />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {companyData?.contact_type === "provider" && (
                            <div className="bg-white rounded-md shadow p-4 border border-grey-light-3 lg:col-span-2 xl:col-span-1">
                                <div className="flex justify-between p-4">
                                    <h2 className="text-blue text-xl">Solicitudes pendientes</h2>
                                    <p className="text-3xl font-bold">{reservationData?.length}</p>
                                </div>
                                <div className="relative h-[258px]">
                                    <div className="overlay absolute h-[30px] left-0 right-3 bottom-0 bg-gradient-to-t from-white from-1% via-30% to-90% pointer-events-none z-10 " />
                                    <div className="max-h-[258px] overflow-y-auto">
                                        {reservationData?.map((reservationItem) => (
                                            <a key={reservationItem.id} href={`/inbox?exp=${reservationItem.id}`}>
                                                <div
                                                    className="flex px-4 py-4 hover:bg-yellow-light-1 rounded-md group justify-between items-center"
                                                    key={reservationItem.id}
                                                >
                                                    <div className="flex">
                                                        <Avatar
                                                            altText={
                                                                reservationItem.name &&
                                                                getInitials(reservationItem.name)
                                                            }
                                                            image={reservationItem?.contact?.profile_image?.image}
                                                            size="xl"
                                                        />
                                                        <div className="ml-6 grid">
                                                            <h3 className="truncate min-w-[0] max-w-[100%]">
                                                                {reservationItem.name}
                                                            </h3>
                                                            <p className="text-grey text-sm truncate min-w-[0] max-w-[100%]">
                                                                {reservationItem.destinations[0].name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="invisible group-hover:visible justify-self-end flex pr-4">
                                                        <div className="w-4">
                                                            <IconButton
                                                                icon="visibility"
                                                                color="grey"
                                                                title="Ver petición"
                                                                extraClass="text-[18px]"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-span-12 bg-white rounded-md p-4 mx-4 sm:mx-8 rounded-md shadow flex gap-8 border border-grey-light-3 h-[370px]">
                        <div className="basis-full md:basis-1/3 flex flex-col h-full">
                            <div className="p-4">
                                <p className="text-blue text-2xl">De viaje hoy</p>
                                <p className="text-grey-light-1 text-lg pt-2"> {currentDateToShow}</p>
                            </div>
                            {areTravelsLoaded ? (
                                <div className="relative h-[calc(100%-110px)]">
                                    <div className="overlay absolute h-[30px] left-0 right-0 bottom-0 bg-gradient-to-t from-white from-1% via-30% to-90% pointer-events-none z-10 " />
                                    <div className="h-full overflow-y-auto">
                                        {todayTravels && todayTravels.length > 0 ? (
                                            todayTravels.map((travel: DashboardTravelData, index) => (
                                                <DashboardTravel
                                                    calendarSelectedDate={calendarSelectedDate}
                                                    key={travel.reservation.id}
                                                    travel={travel}
                                                    index={index}
                                                    highlightCurrentDay
                                                />
                                            ))
                                        ) : (
                                            <p className="text-grey-light-1 text-2xl flex h-full items-center text-center p-4">
                                                No hay reservas para ese día
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="h-full flex justify-center items-center p-20 opacity-25">
                                    <div className="w-28">
                                        <EngineSpinner />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="hidden md:block md:basis-2/3">
                            <Map
                                tooltipHeight={200}
                                tooltipWidth={180}
                                arrayOfTravels={todayTravels}
                                isDayChanged={isDayChanged}
                                calendarSelectedDate={calendarSelectedDate}
                                showTooltipAction={showTooltipAction}
                            />
                        </div>
                    </div>
                </div>
            </ScreenTemplate>
        )
    );
};

export default DashboardScreen;
