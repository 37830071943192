import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

export interface InfoTooltipData {
    /**
     * text to be shown in the tooltip
     */
    infoText: string;

    /**
     * Custom css class
     */
    extraClass?: string;
}

const InfoTooltip: React.FC<InfoTooltipData> = ({ infoText, extraClass }) => (
    <Tooltip
        title={infoText}
        PopperProps={{
            sx: {
                "& .MuiTooltip-tooltip": {
                    border: "solid #ffbe00 1px",
                    color: "black",
                    background: "white",
                    fontSize: "0.8em",
                    padding: "1rem",
                },
            },
        }}
    >
        <InfoIcon className={`text-yellow cursor-pointer ${extraClass}`} />
    </Tooltip>
);

export default InfoTooltip;
