import React from "react";
import classNames from "classnames";
import Muicheckbox from "@mui/material/Checkbox";

export interface ListSelectionButtonProps {
    /**
     * Sets the display of the button to have a yellow border, showing that
     * the row associated to this button is selected.
     */
    selected: boolean;

    /**
     * Click handler, set by the parent container, which will usually be
     * a select toggle for single rows, and "select all" behavior for the
     * header button.
     */
    onClick: () => void;
}

/**
 * A circle that the user can click to select a row or multiple rows.
 */
const ListSelectionButton: React.FC<ListSelectionButtonProps> = ({ selected, onClick }) => (
    <div className={classNames("w-14 h-12 flex items-center")}>
        <Muicheckbox
            sx={{
                paddingLeft: 0,
            }}
            checked={selected}
            onClick={onClick}
            disableRipple
            onKeyPress={onClick}
        />
    </div>
);

export default ListSelectionButton;
