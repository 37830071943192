import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import React, { useState, useRef } from "react";
import GroupIcon from "@mui/icons-material/Group";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import DashboardTravelItinerary from "./DashboardTravelItinerary";
import map from "../maps/worldmap.json";
import { DashboardTravelData } from "../types";

export interface MapData {
    /**
     * Array o ftravels to be shown in the map
     */
    arrayOfTravels: DashboardTravelData[];

    /**
     * tooltip width dimension
     */
    tooltipWidth: number;

    /**
     * tooltip height dimension
     */
    tooltipHeight: number;

    /**
     * Receives from parnet if date has changed in calendar
     */
    isDayChanged: boolean;

    /**
     * Receives selected date in calendar
     */
    calendarSelectedDate: string;

    showTooltipAction: (tooltipVisible: boolean) => void;
}

const Map: React.FC<MapData> = ({
    arrayOfTravels,
    tooltipWidth,
    tooltipHeight,
    isDayChanged,
    calendarSelectedDate,
    showTooltipAction,
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
    const [tooltipX, setTooltipX] = useState<string>();
    const [tooltipY, setTooltipY] = useState<string>();
    const [clickedCountry, setClickedCountry] = useState<string>();
    const tootltipWidthDimension = `${tooltipWidth}px`;
    const tootltipHeightDimension = `${tooltipHeight}px`;
    const nextDaysInMap: string[] = [];
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    arrayOfTravels.map((element) => nextDaysInMap.push(element.country_code));

    /**
     * Helper that returns the exact day of return
     * @param travel
     * @returns string
     */
    const getReturnDate = (travel: DashboardTravelData) => {
        const departureDate = travel.reservation.travel_start_date;
        const newdate = new Date(departureDate);
        newdate.setDate(newdate.getDate() + travel.reservation.itinerary_days);
        const date = `0${newdate.getDate()}`;
        const month = `0${newdate.getMonth() + 1}`;
        const year = newdate.getFullYear();
        const dateOfReturn = `${date.slice(-2)}-${month.slice(-2)}-${year}`;
        return dateOfReturn;
    };
    /**
     * Function for handling tooltip positioning
     * @param x number
     * @param y number
     * @param elementId number
     */
    function customTooltipPosition(x: number, y: number, elementId: string) {
        if (mapContainerRef.current && nextDaysInMap.includes(elementId)) {
            const mapContainerDimensions = mapContainerRef.current.getBoundingClientRect();
            const tooltipHeightfromRef = tooltipRef.current?.getBoundingClientRect().height;
            const mapContainerOffsetTop = mapContainerDimensions.y;
            const mapContainerOffsetLeft = mapContainerDimensions.x;
            const mapContainerHeight = mapContainerDimensions.height;
            const mapContainerWidth = mapContainerDimensions.width;
            const realTop = window.innerHeight - (window.innerHeight - mapContainerOffsetTop);
            const realLeft = window.innerWidth - (window.innerWidth - mapContainerOffsetLeft);
            const offsetTop = -(realTop - y - window.scrollY);
            const offsetLeft = -(realLeft - x - window.scrollX);
            let coefficientY = 0;
            let coefficientX = 15;

            if (tooltipHeightfromRef && offsetTop > mapContainerHeight - tooltipHeightfromRef) {
                offsetTop < tooltipHeightfromRef
                    ? (coefficientY = -(tooltipHeightfromRef / 2 + 10))
                    : (coefficientY = -tooltipHeightfromRef);
            }
            if (offsetLeft > mapContainerWidth - tooltipWidth - 20) {
                coefficientX = -(tooltipWidth + 15);
            }

            const tooltipPositionY = offsetTop + coefficientY;
            const tooltipPositionX = offsetLeft + coefficientX;

            setTooltipX(`${tooltipPositionX}px`);
            setTooltipY(`${tooltipPositionY}px`);
            showTooltipAction(true);
        } else {
            setIsTooltipVisible(false);
            showTooltipAction(false);
        }
    }

    return (
        <div className="mapContainer overflow-hidden relative h-full w-full" id="mapContainer" ref={mapContainerRef}>
            <div className="overlay absolute top-0 left-0 right-0 bottom-0 shadow-map pointer-events-none" />
            <div
                ref={tooltipRef}
                className={`border border-grey-light-3 ${
                    isTooltipVisible && isDayChanged ? "" : "invisible pointer-events-none"
                }`}
                style={{
                    top: tooltipY,
                    left: tooltipX,
                    background: "#FFF",
                    position: "absolute",
                    width: tootltipWidthDimension,
                    maxHeight: tootltipHeightDimension,
                    zIndex: "99",
                    overflow: "auto",
                    borderRadius: "4px",
                }}
            >
                {arrayOfTravels &&
                    arrayOfTravels.length > 0 &&
                    arrayOfTravels
                        .filter((travel) => clickedCountry === travel.country_code)
                        .map((travel: DashboardTravelData, index: number) => (
                            <div
                                key={travel.reservation.id}
                                className={`${
                                    arrayOfTravels.length !== index + 1 ? "border-b border-grey-light-3" : ""
                                } p-2`}
                            >
                                <h2 className=" text-sm text-grey-dark-4">
                                    {travel.reservation.clients[0]?.full_name}
                                </h2>
                                <div className="flex flex-row">
                                    <div className="flex">
                                        <span title="viajeros">
                                            <GroupIcon
                                                className="text-yellow relative bottom-[1px]"
                                                sx={{ fontSize: "16px" }}
                                            />
                                        </span>

                                        <span className="text-grey-dark-2 text-xs pl-1 flex items-center">
                                            {travel.reservation.amount_travelers}
                                        </span>
                                    </div>
                                    <div className="flex pl-4">
                                        <span title="viajeros">
                                            <FlightLandIcon
                                                className="text-yellow relative bottom-[1px]"
                                                sx={{ fontSize: "16px" }}
                                            />
                                        </span>

                                        <span className="text-grey-dark-2 text-xs pl-1 flex items-center">
                                            {getReturnDate(travel)}
                                        </span>
                                    </div>
                                </div>
                                <DashboardTravelItinerary
                                    showOnlyNextDay
                                    travel={travel}
                                    calendarSelectedDate={calendarSelectedDate}
                                />
                            </div>
                        ))}
            </div>

            <style>{".rsm-svg { object-fit:cover; height:100%;}"}</style>
            <ComposableMap height={340} className="w-full" onClick={() => setIsTooltipVisible(false)}>
                <ZoomableGroup center={[0, 20]} zoom={1}>
                    <Geographies geography={map}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                <Geography
                                    key={geo.rsmKey}
                                    id={geo.id}
                                    className={`${geo.name} cursor-pointer`}
                                    geography={geo}
                                    fill={nextDaysInMap.includes(geo.id) ? "#ffbe00" : "#F5F5F8"}
                                    stroke="#CCC"
                                    strokeWidth={0.3}
                                    style={{
                                        default: { outline: "none" },
                                        hover: { outline: "none", fill: "#ffbe00" },
                                        pressed: { outline: "red", fill: "#343434" },
                                    }}
                                    onClick={(ev) => {
                                        setClickedCountry(geo.id);
                                        ev.stopPropagation();
                                        customTooltipPosition(ev.pageX, ev.pageY, geo.id);
                                        setIsTooltipVisible(true);
                                    }}
                                />
                            ))
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
        </div>
    );
};

export default Map;
