import React, { useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import DashboardTravelItinerary from "./DashboardTravelItinerary";
import Avatar from "./Avatar";
import { DashboardTravelData, TravelDayData } from "../types";
import { getCurrentDate, getInitials, getReturnDate } from "../utils";
import IconButton from "./IconButton";

export interface DashboardTravelItemData {
    /**
     * travel item
     */
    travel: DashboardTravelData;

    /**
     * travel index
     */
    index: number;

    /**
     * Receives selected day from calendar
     */
    calendarSelectedDate: string;
}

const DashboardTravel: React.FC<DashboardTravelItemData> = ({ travel, index, calendarSelectedDate }) => {
    const [isTravelDetailVisible, setIstravelDetailVisibe] = useState<boolean>(false);
    const [isHighlighColorVisible, setIsHighlighColorVisible] = useState<boolean>(false);

    const globalItinerary: TravelDayData[] = [];
    travel.reservation.itinerary.map((itineraryItem) =>
        itineraryItem.days.map((day: TravelDayData) => globalItinerary.push(day))
    );

    const handleTravelDetail = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setIstravelDetailVisibe(!isTravelDetailVisible);
        setIsHighlighColorVisible(!isHighlighColorVisible);
    };

    return (
        <a key={index} href={`/reservations?exp=${travel.reservation.id}`}>
            <div
                className={`flex px-4 py-4 hover:bg-yellow-light-1 ${
                    isHighlighColorVisible ? "bg-yellow-light-1" : ""
                } rounded-md group justify-between items-center overflow-y-auto`}
            >
                <div className="flex">
                    {travel?.reservation?.clients[0]?.profile_image ? (
                        <Avatar
                            altText={
                                travel?.reservation?.clients[0]?.full_name &&
                                getInitials(travel.reservation.clients[0].full_name)
                            }
                            image={
                                travel?.reservation?.clients[0]?.profile_image &&
                                travel?.reservation?.clients[0]?.profile_image
                            }
                            size="xl"
                        />
                    ) : (
                        <Avatar
                            altText={
                                travel?.reservation?.clients[0]?.full_name &&
                                getInitials(travel.reservation.clients[0].full_name)
                            }
                            size="xl"
                        />
                    )}

                    <div className="ml-6 grid">
                        <h3 className=" truncate min-w-[0] max-w-[100%]">
                            {travel.reservation?.clients[0]?.full_name}
                        </h3>
                        <p className="text-grey text-sm truncate min-w-[0] max-w-[100%]">
                            <span>
                                {travel?.location?.name}
                                {travel?.location?.name && ", "}
                            </span>
                            <span> {travel?.country?.name}</span>
                        </p>
                    </div>
                </div>
                <div className="block group-hover:hidden justify-self-end flex pr-1 ml-2">
                    <div className="text-grey-light-1 flex flex-col items-end">
                        <p className="text-md">
                            {getCurrentDate(travel.reservation.travel_start_date, "dayFirst", "slashed")}
                        </p>
                        <p className="text-xs">
                            {travel.reservation.itinerary_days > 1
                                ? `${travel.reservation.itinerary_days} dias `
                                : `${travel.reservation.itinerary_days} dia `}
                            de viaje
                        </p>
                    </div>
                </div>

                <div className="hidden group-hover:block justify-self-end flex pr-2 ml-2">
                    <div className="w-4">
                        <IconButton
                            icon="visibility"
                            color="grey"
                            title="Ver petición"
                            extraClass="text-[18px]"
                            onClick={handleTravelDetail}
                        />
                    </div>
                </div>
            </div>
            {isTravelDetailVisible && (
                <div className="travelDetail pb-4 border-b  border-grey-light-3">
                    <div className="p-2">
                        <h2 className="text-sm text-grey-dark-4 mt-2">
                            {travel.reservation.title || "Sin título asignado"}
                        </h2>
                        <div className="flex flex-row flex-wrap">
                            <div className="flex">
                                <span title="viajeros">
                                    <GroupIcon
                                        className="text-yellow relative bottom-[1px]"
                                        sx={{ fontSize: "16px" }}
                                    />
                                </span>

                                <span className="text-grey-dark-2 text-xs pl-1 pr-4 flex items-center">
                                    {travel.reservation.amount_travelers}
                                </span>
                            </div>
                            <div className="flex ">
                                <span title="viajeros">
                                    <FlightTakeoffIcon
                                        className="text-yellow relative bottom-[1px]"
                                        sx={{ fontSize: "16px" }}
                                    />
                                </span>

                                <span className="text-grey-dark-2 text-xs pl-1 pr-4 flex items-center">
                                    {travel.reservation.travel_start_date.split("-").reverse().join("-")}
                                </span>
                            </div>
                            <div className="flex">
                                <span title="viajeros">
                                    <FlightLandIcon
                                        className="text-yellow relative bottom-[1px]"
                                        sx={{ fontSize: "16px" }}
                                    />
                                </span>

                                <span className="text-grey-dark-2 text-xs pl-1 flex items-center">
                                    {getReturnDate(travel)}
                                </span>
                            </div>
                        </div>
                        <DashboardTravelItinerary travel={travel} calendarSelectedDate={calendarSelectedDate} />
                    </div>
                </div>
            )}
        </a>
    );
};

export default DashboardTravel;
