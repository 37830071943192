import React from "react";
import Lottie from "lottie-react-web";
import EngineAnimation from "../../lotties/engine.json";

const Engine: React.FC = () => (
    <div className="flex justify-center items-center">
        <div className="w-60">
            <Lottie
                options={{
                    animationData: EngineAnimation,
                }}
                speed={2}
            />
        </div>

        {/* <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow" /> */}
    </div>
);

export default Engine;
